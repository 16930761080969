.federated-sign-in-button:hover {
  border: 1px solid black;
}

.amplify-button--link:hover {
  text-decoration: underline;
}
/* 
::-webkit-scrollbar {
  display: none;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.breathing-button-fast {
  -webkit-animation: breathing 2s ease-out infinite normal;
  animation: breathing 2s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
}

.breathing-button-chill {
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 5s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/* Global CSS or Tailwind's global styles */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}


/* Add this to your global CSS file or inside a specific CSS file */
.swiper-pagination-bullets .swiper-pagination-bullet {
  box-shadow: 0 0 4px 1px white; /* White shadow */
}
/* Add this to your global CSS file or inside a specific CSS file */
.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: black; /* Default dot color */
  opacity: 1; /* To ensure full opacity */
}

@keyframes reflex {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
